import React from "react"
import SVGIcon from "../../components/common/SVGIcon"
function PatternIconLarge() {
  return (
    <>
      <div className="pattern-icons-large anim-about-us">
        <div className="left">
          <SVGIcon name="pattern/larrowp-spark" className="larrowp larrow large"/>
          <SVGIcon name="pattern/lcircleb-circle" className="lcircle1p lcircle1 medium"/>
          <SVGIcon name="pattern/lcirclep-circle" className="lcircle2p lcircle2"/>
          <SVGIcon name="pattern/rdatap-document" className="ldatap ldata large"/>
          <SVGIcon name="pattern/ldevp-dev" className="ldevp ldev large"/>
          <SVGIcon name="pattern/ldev2p" className="ldev2p ldev2"/>
          <SVGIcon name="pattern/wire" className="lwirep lwire"/>
          {/* <div className="larrowp larrow large" />
          <div className="lcircle1p lcircle1 medium" />
          <div className="lcircle2p lcircle2" />
          <div className="ldatap ldata large" />
          <div className="ldevp ldev large" />
          <div className="ldev2p ldev2" />
          <div className="lwirep lwire" /> */}
        </div>
        <div className="right">
          <SVGIcon name="pattern/rarrowp-large" className="rarrowp rarrow large"/>
          <SVGIcon name="pattern/rcirclep-rcircle" className="rcirclep rcircle"/>
          <SVGIcon name="pattern/rdatap-blue" className="rdatap rdata large"/>
          <SVGIcon name="pattern/rharddisc" className="rharddisc1p rharddisc1"/>
          <SVGIcon name="pattern/rharddisc2p" className="rharddisc2p rharddisc2"/>
          <SVGIcon name="pattern/rslashp-rslash" className="rslashp rslash medium"/>
          <SVGIcon name="pattern/larrowp-spark" className="larrowp rspark large"/>
          {/* <div className="rarrowp rarrow large" />
          <div className="rcirclep rcircle" />
          <div className="rdatap rdata large" />
          <div className="rharddisc1p rharddisc1" />
          <div className="rharddisc2p rharddisc2" />
          <div className="rslashp rslash medium" />
          <div className="larrowp rspark large" /> */}
        </div>
      </div>
    </>
  )
}

export default PatternIconLarge