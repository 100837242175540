import React, { useState } from "react"
import parse from "html-react-parser"
import { Col, Row } from "react-bootstrap"
import LazyLoad from "react-lazyload"
import CarouselComponent from "./carousel-component"
import Fade from "react-reveal/Fade"
import Slide from "../utility/slide_content"

const UspInteractiveNew = props => {
  const { items, heading, displayWidth } = props
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentImage, setCurrentImage] = useState(
    <LazyLoad className="uspDesktopImage">
      <Fade bottom delay={300} duration={500} distance="30px">
        <img src={items[0].picture.url} />
      </Fade>
    </LazyLoad>
  )

  const responsive = {
    all: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  }

  const onchange = data => {
    setCurrentIndex(data)
    setCurrentImage(
      <LazyLoad className="uspDesktopImage">
        <Fade bottom delay={300} duration={500} distance="30px">
          <img src={items[data].picture.url} />
        </Fade>
      </LazyLoad>
    )
  }

  let CarousalCards = items.map((item, i) => {
    return (
      <Row>
        <Col className="right" lg={6}>
          {/* <LazyLoad>
            <img className="uspCarousalImage" src={item.picture.url} />
          </LazyLoad> */}
        </Col>
        <Col className="left" lg={6}>
          <div className="uspCarousalCard">
            <Fade bottom delay={200} duration={500} distance="30px">
              <div className="p14 breadcrumb">
                <p>{item.title}</p>
              </div>
            </Fade>

            <Fade bottom delay={300} duration={500} distance="30px">
              <div className="title">{parse(item.titleText.html)}</div>
            </Fade>

            <Fade bottom delay={400} duration={500} distance="30px">
              <div className="description p16">
                {parse(item.descriptionText.html)}
              </div>
            </Fade>
          </div>
        </Col>
      </Row>
    )
  })
  return (
    <div
      style={{ backgroundColor: items[currentIndex].background }}
      className="usp-card"
    >
      <div className="left">
        {heading}
        <div className="position-relative">
          <CarouselComponent
            responsive={responsive}
            children={CarousalCards}
            swipeable
            status
            statusTextRight="features"
            infinite={false}
            onChange={onchange}
            customTransition="all 1"
          />
        </div>
      </div>
      <div className="right">{currentImage}</div>
    </div>
  )
}

export default UspInteractiveNew
