import { Link } from "gatsby"
import React from "react"
import LazyLoad from "react-lazyload"
import "../../styles/component/integration-card.scss"
import SVGIcon from "./SVGIcon"

const Integrations = ({ data }, props) => {
  function createCards(dt, st) {
    let cardArray = []
    dt.map(integ => {
      const { integrationCards, slug } = integ
      const { title, image, subtext } = integrationCards
      let card = title.length ? (
        <Link to={`/${slug}`}>
          <div className="integration-card-new position-relative">
            <LazyLoad once offset={100} className="justify-content-center">
              <img loading="lazy" src={image[1]?.url} alt={title} />
            </LazyLoad>
            <div className="p13 mt16">
              <p>{title}</p>
            </div>
            {/* {subtext.length !== 0 && (
            <span className="tag">
              <p>{subtext[0]}</p>
            </span>
          )} */}
          </div>
        </Link>
      ) : (
        <div className="sps-logo-wrapper align-items-center justify-content-center">
          <SVGIcon
            name="home/spsLogoSmall"
            alt="SuperOps"
            className="position-relative"
          />
        </div>
      )

      if (st === "signup" && slug === st) {
        cardArray.push(card)
      }
      if (st === "available" && slug !== "signup") {
        cardArray.push(card)
      }
    })

    return cardArray
  }
  return (
    <div className="integration-cards-new">
      {createCards(data, "available")}
      {createCards(data, "signup")}
    </div>
  )
}

export default Integrations
