import HeroImage from "./new-banner-1x.webp"
import NewHeroImage from "./new-home-banner.webp"
import Screenshot from "./promo-video-thumbnail.webp"
import newuspinteractive0 from "./new-usp-interactive-0.webp"
// import newuspinteractive1 from "./new-usp-interactive-0.webp"
// import newuspinteractive2 from "./new-usp-interactive-0.webp"
import uspinteractive0 from "./usp-interactive-0.webp"
import uspinteractive1 from "./usp-interactive-1.webp"
import uspinteractive2 from "./usp-interactive-2.webp"
import itops from "./itops-illustration.webp"
import aiops from "./aiops-illustration.webp"
import complainceops from "./complianceops-illustration.webp"
import revops from "./revops-illustration.webp"
import Arrow from "./hypertext-arrow-right.svg"

const Images = {
  hero: HeroImage,
  newHero: NewHeroImage,
  videoScreenshot: Screenshot,
  uspinteractive: [uspinteractive0, uspinteractive1, uspinteractive2],
  newuspinteractive: [newuspinteractive0, newuspinteractive0, newuspinteractive0, newuspinteractive0],
  renderFeatures: [itops, aiops, complainceops, revops],
  rightArrow: Arrow,
}

export default Images
