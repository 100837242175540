import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import "../../styles/component/cta-box-lp.scss"

import PatternCTABoxLightLp from "../../components/common/pattern-cta-lp"


function CTABoxLp(props) {

  return (
    <>
      {props.theme === "light" && (
        <div className={` mx-auto cta-box-lp ${props.theme}`}>
          <Container className="position-relative light-box-lp-inner">
            <PatternCTABoxLightLp />
            <Row>
              <Col lg={8} className="mx-auto">
                <div className="block text-center cta-lp ">
                  <h2 className="heading"> {props.heading} </h2>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  )
}

export default CTABoxLp
