import React from "react"
import Slide from "../utility/slide_content"

function FeaturesList(props) {
  return (
    <div className="grid-container">
      {props.content.map((desc, i) => {
        return (
          <div className="grid-lg-3 grid-md-2 grid-sm-1">
            <Slide style={{ "--sal-delay": "0.3s" }}>
              <div className="icon position-relative">
                <img
                  src={props.icons[i].url}
                  alt="icon"
                  width="10"
                  height="10"
                />
              </div>
              <span className="p16">
                <p>{desc}</p>
              </span>
            </Slide>
          </div>
        )
      })}
    </div>
  )
}

export default FeaturesList
