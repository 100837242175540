import React from "react"
import { StaticQuery, graphql } from "gatsby"
import LazyLoad from "react-lazyload"
import Images from "./../assets/images/home/home-images"

function LandingPageFeatures(props) {
  return (
    <StaticQuery
      query={graphql`
        query platformV1ITOps {
          SuperOps {
            navigationContents(where: { navigation: "MSP Platform" }) {
              parentTitle
              parentDescription
              childTitle
              childSlug
              childDescription
              childIcon {
                url
              }
              parentIllustration {
                url
              }
              boxBg
            }
          }
        }
      `}
      render={data => (
        <div className="grid-container">
          {data.SuperOps.navigationContents.map((dp, i) => {
            const {
              parentTitle,
              parentDescription,
              childTitle,
              childSlug,
              childDescription,
              childIcon,
              parentIllustration,
              boxBg,
            } = dp

            return (
              <div className="grid-lg-2 grid-md-2 grid-sm-1">
                {/* <Slide style={{ "--sal-delay": "0.2s" }}> */}
                <div
                  className="item h100"
                  style={{
                    background: boxBg,
                    minHeight: props.bottom === "sm" && "250px",
                  }}
                >
                  <div className="feature-header-wrap">
                    <div className="feature-img-wrap">
                      {!!parentIllustration && (
                        <LazyLoad once offset={100}>
                          <img
                            src={Images.renderFeatures[i]}
                            alt="icon"
                            className="icon"
                          />
                        </LazyLoad>
                      )}
                    </div>
                    <div className="feature-head-container">
                      <span className="alt active-bg">
                        <h5>{parentTitle}</h5>
                      </span>

                      <p className="feature-des">{parentDescription}</p>
                    </div>
                  </div>

                  <div className="plaform-feature-list">
                    {childTitle.map((title, i) => {
                      return (
                        <div className="p11 a13 feature-items-wrap">
                          {props.showLinks === "Yes" && (
                            <div className="plinks">
                              <a
                                href={childSlug[i]}
                                className="position-relative"
                              >
                                <LazyLoad once offset={100}>
                                  <img
                                    src={childIcon[i].url}
                                    alt="icon"
                                    height="10"
                                    width="10"
                                    className="icon"
                                  />
                                </LazyLoad>

                                {title}
                                <LazyLoad once offset={100}>
                                  <img
                                    src={Images.rightArrow}
                                    alt="arrow"
                                    height="7"
                                    width="4"
                                    className="arrow"
                                  />
                                </LazyLoad>
                                <p className="feature-discription">
                                  {childDescription[i]}
                                </p>
                              </a>
                            </div>
                          )}

                          {props.showLinks !== "Yes" && (
                            <p>
                              <LazyLoad once offset={100}>
                                <img
                                  src={childIcon[i].url}
                                  alt="icon"
                                  height="10"
                                  width="10"
                                />
                              </LazyLoad>
                              {title}
                            </p>
                          )}
                        </div>
                      )
                    })}
                  </div>
                </div>
                {/* </Slide> */}
              </div>
            )
          })}
        </div>
      )}
    />
  )
}

export default LandingPageFeatures
