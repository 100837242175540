import React from "react"
import Slide from "../../components/utility/slide_content"
import Buttons from "./button"
import LandingPageFeatures from "../LandingPageFeatures"
import '../../styles/component/platform-v1.scss'

function LandingPagePlatform(props) {
  return (
    <div className="platform-v1">
      <Slide style={{ "--sal-delay": "0.3s" }}>
        <h2 className="">{props.heading}</h2>
      </Slide>
      <div className="p16">
        <Slide style={{ "--sal-delay": "0.4s" }}>
          <p>{props.description}</p>
        </Slide>
      </div>
      <div className="platform-wrap">
        <div className="cards position-relative">
          <LandingPageFeatures bottom="sm" showLinks={props.showLinks} />
        </div>
      </div>
      <div className="mt50 mb50">
        <Buttons
          theme="secondary"
          arrow
          link="/features"
          text="VIEW ALL FEATURES"
        />
      </div>
    </div>
  )
}

export default LandingPagePlatform
